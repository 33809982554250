<nav class="navbar navbar-expand-lg justify-content-between bg-secondary" [ngClass]="{ 'navbar-custome': !checkLogin, navbarLogin: checkLogin }">
    <div class="container">
        <a href="https://guide.idinsight.org/" class="navbar-brand">
            <img src="../../../assets/idIndLogo.png" alt="" width="277px" height="82px" />
        </a>
        <button class="navbar-toggler" type="button" style="background-color: #472666" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed">
      <span class="material-icons" style="color: #ffffff"> menu </span>
    </button>
        <div class="collapse navbar-collapse" [collapse]="isCollapsed" id="navbarNav" [ngClass]="{ pdNotLogin: !checkLogin, PdLogin: checkLogin }">
            <ul class="navbar-nav ms-auto">
                <li class="nav-item">
                    <a href="https://guide.idinsight.org/guide/" [routerLinkActive]="'active'" class="menuLink">Guide</a>
                </li>
                <li class="nav-item">
                    <div class="btn-group menuLink" dropdown #dropdown="bs-dropdown" (mouseenter)="dropdown.show()" (mouseleave)="dropdown.hide()">
                        <a href="https://guide.idinsight.org/method/" id="button-basic-method" class="method-btn" style="position: relative; bottom: 8px; background-color: #ccb5e3;" dropdownToggle aria-controls="dropdown-basic-method">
              Method
            </a>
                        <ul id="dropdown-basic-method" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic-method" [ngClass]="{
                'm-menu-Notlogin': !checkLogin,
                'm-menu-login': checkLogin
              }" style="width: 14rem; height: auto">
                            <!-- <ul role="menu" class="dropdown-menu menu-depth-2nd drdt-ignore-dark"> -->
                            <li class="methodLi" style="font-size: 12px; color: #ccb5e3; margin-top: 10px">
                                METHODS
                            </li>
                            <li class="methodLi" style="padding-top: 8px">
                                <a title="Theory of Change" class="method-link" href="https://guide.idinsight.org/method/theory-of-change/">Theory of Change&nbsp;</a>
                            </li>
                            <li class="methodLi">
                                <a title="Needs Assessment" class="method-link" href="https://guide.idinsight.org/method/needs-assessment/">Needs Assessment&nbsp;</a>
                            </li>
                            <li class="methodLi">
                                <a title="Evidence Review" class="method-link" href="https://guide.idinsight.org/method/evidence-review-2/">Evidence Review&nbsp;</a>
                            </li>
                            <li class="methodLi">
                                <a title="Process Evaluation" class="method-link" href="https://guide.idinsight.org/method/process-evaluation/">Process Evaluation&nbsp;</a>
                            </li>
                            <li class="methodLi">
                                <a title="Impact evaluation" class="method-link" href="https://guide.idinsight.org/method/impact-evaluation/">Impact evaluation&nbsp;</a>
                            </li>
                            <li class="methodLi">
                                <a title="Monitoring" class="method-link" href="https://guide.idinsight.org/method/monitoring-2/">Monitoring&nbsp;</a>
                            </li>
                            <!-- </ul> -->
                            <li class="divider dropdown-divider"></li>
                            <li class="methodLi" style="font-size: 12px; color: #ccb5e3">
                                INTERACTIVE TOOLS
                            </li>
                            <li class="methodLi" style="padding-top: 8px">
                                <a title="Theory of Change Builder" class="method-link" href="https://guidetool.idinsight.org/tocBuilder/index">Theory of Change Builder&nbsp;</a>
                            </li>
                            <li class="methodLi">
                                <a title="Indicator Recommender" class="method-link" href="https://guidetool.idinsight.org/tocBuilder/viewIndicator">Indicator Recommender&nbsp;</a>
                            </li>
                            <li class="methodLi" style="padding-bottom: 1rem">
                                <a title="Survey Builder" class="method-link" href="https://guidetool.idinsight.org/survey-builder/createSurvey">Survey Builder&nbsp;</a>
                            </li>
                        </ul>
                    </div>
                </li>
                <li class="nav-item">
                    <a href="https://guide.idinsight.org/about/" [routerLinkActive]="'active'" class="menuLink">About</a>
                </li>
                <!-- <li class="nav-item">
                  <a href="https://guide.idinsight.org/account/" [routerLinkActive]="'active'" class=" menuLink">Account</a>
                      </li> -->
                <li class="nav-item">
                    <a *ngIf="!checkLogin" [routerLink]="['sign-in']" [routerLinkActive]="'active'" class="btn sign-lk">Sign
            In</a>
                </li>
                <li class="nav-item">
                    <div class="btn-group" dropdown #dropdown="bs-dropdown" *ngIf="checkLogin" (mouseenter)="dropdown.show()" (mouseleave)="dropdown.hide()">
                        <i class="fa fa-user-circle fa-lg" id="button-basic" dropdownToggle aria-controls="dropdown-basic" style="color: #472666; cursor: pointer; font-size: 28px" aria-hidden="true"></i>
                        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic" style="margin-left: -11rem; text-align: center; width: 14rem">
                            <i class="fa fa-user-circle fa-lg" style="
                  color: #472666;
                  font-size: 38px;
                  margin-bottom: 1rem;
                  margin-top: 0.5rem;
                "></i>
                            <li role="menuitem" style="font-size: 14px; line-height: 2">
                                {{ userfName }} {{ userlName }}
                            </li>
                            <li role="menuitem" style="
                  font-size: 12px;
                  line-height: 1;
                  font-weight: 400;
                  margin-bottom: 1rem;
                ">
                                {{ userEmail }}
                            </li>

                            <!-- <li role="menuitem " style="cursor: pointer; line-height: 1;
                           font-size: 14px; margin-top: 1.5rem; margin-left: 10%;
                      margin-right: 10%;">
                              <a class="dropdown-item updateBtn"
                              [routerLink]="['/sign-up']" [queryParams]="{fromUpdate:true}"
                               style="letter-spacing: normal;" routerLinkActive="active">Update Profile</a>
                      </li> -->
                            <li class="divider dropdown-divider"></li>
                            <li role="menuitem" style="font-size: 14px; line-height: 1">
                                <a class="dropdown-item hovNon" style="letter-spacing: normal" [routerLink]="['/sign-up']" [queryParams]="{ fromUpdate: true }">Update Profile</a>
                            </li>
                            <li class="divider dropdown-divider"></li>
                            <li role="menuitem" style="font-size: 14px; line-height: 1">
                                <a class="dropdown-item hovNon" style="letter-spacing: normal" href="https://guide.idinsight.org/account/">My Progress</a>
                            </li>
                            <li class="divider dropdown-divider"></li>

                            <li role="menuitem" style="cursor: pointer; line-height: 1; font-size: 14px">
                                <a class="dropdown-item hovNon" style="color: initial; padding: 0.5rem 1rem; font-size: 14px" (click)="signOut()">Signout</a>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</nav>
<div class="col-md-12 secondaryLinkerCls text-center bg-secondary" *ngIf="checkLogin">
    <a routerLink="/admin-dashboard" mat-button class="secondaryMenuLink" [routerLinkActive]="'active'" *ngIf="levelId">Dashboard</a>
    <a routerLink="/sector" mat-button class="secondaryMenuLink" [routerLinkActive]="'active'" *ngIf="levelId">Sector</a>
    <a routerLink="/tocBuilder/index" mat-button class="secondaryMenuLink" [routerLinkActive]="'active'">ToC Builder</a>
    <!-- <a routerLink="/indicators" mat-button class="secondaryMenuLink" [routerLinkActive]="'active'">Indicator
    Recommender</a> -->
    <a routerLink="tocBuilder/viewIndicator" mat-button class="secondaryMenuLink" [routerLinkActive]="'active'">Indicator
      Recommender</a>
    <!-- <a routerLink="/questions" mat-button class="secondaryMenuLink" [routerLinkActive]="'active'">Question Bank</a> -->
    <a routerLink="/survey-builder/createSurvey" mat-button class="secondaryMenuLink" [routerLinkActive]="'active'">Survey
    Builder</a>
    <a routerLink="/users" mat-button class="secondaryMenuLink" [routerLinkActive]="'active'" *ngIf="levelId">Users</a>
</div>
<!-- old nav-bar -->
<!-- <mat-toolbar color="primary" style="background-color: #ccb5e3;" [ngClass]='{"navbar-custome": !checkLogin, "navbarLogin": checkLogin}'>
      <div class="row" style="width: 100%;">
          <div class="col-sm-6 logo">

              <a href="https://guide.idinsight.org/">
                  <img src="../../../assets/idIndLogo.png" alt="" width="277px" height="82px">
              </a>
      </div>

      <div class="col-sm-6 " [ngClass]='{"linkerCls": !checkLogin, "loginLinkerCls": checkLogin}' style="text-align: right;">

          <a href="https://guide.idinsight.org/guide/" [routerLinkActive]="'active'" class="menuLink">Guide</a>
          <a href="https://guide.idinsight.org/method/" [routerLinkActive]="'active'" class="menuLink">Method</a>
          <a href="https://guide.idinsight.org/about/" [routerLinkActive]="'active'" class="menuLink">About</a>
          <a href="https://guide.idinsight.org/account/" [routerLinkActive]="'active'" class=" menuLink">Account</a>

          <a *ngIf="!checkLogin" [routerLink]="['sign-in']" [routerLinkActive]="'active'" class="btn sign-lk">Sign In</a>
          <div class="btn-group" dropdown #dropdown="bs-dropdown" *ngIf="checkLogin" (mouseenter)="dropdown.show()" (mouseleave)="dropdown.hide()">
              <i class="fa fa-user-circle fa-lg" id="button-basic" dropdownToggle type="button" aria-controls="dropdown-basic" style="color: #472666; cursor: pointer; padding-top: 5px;
               " aria-hidden="true"></i>
              <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic" style="margin-left: -10rem; text-align: center; width: 12rem;">
                  <i class="fa fa-user-circle fa-lg" style="color: #472666; font-size: 38px;
                  margin-bottom: 1rem; margin-top: .5rem;"></i>
                  <li role="menuitem" style="font-size: 14px; line-height: 2;">{{userfName}} {{userlName}}</li>
                  <li role="menuitem" style="font-size: 12px; line-height: 1; font-weight: 400; margin-bottom: 1rem;">
                      {{userEmail}}</li>

                  <li role="menuitem " style="cursor: pointer; line-height: 1; font-size: 14px; margin-top: 2rem; margin-left: 8%;
                      margin-right: 8%;">
                      <a class="dropdown-item updateBtn" [routerLink]="['/sign-up']" [queryParams]="{fromUpdate:true}" routerLinkActive="active">Update Profile</a>
                  </li>
                  <li class="divider dropdown-divider"></li>

                  <li role="menuitem" style="cursor: pointer; line-height: 1;">
                      <a class="dropdown-item" (click)="signOut()">Signout</a>
                  </li>
              </ul>
          </div>
      </div>
      <div class="col-md-12 secondaryLinkerCls text-center" *ngIf="checkLogin">
          <a routerLink="/admin-dashboard" mat-button class="secondaryMenuLink" [routerLinkActive]="'active'" *ngIf="levelId">Dashboard</a>
          <a routerLink="/sector" mat-button class="secondaryMenuLink" [routerLinkActive]="'active'" *ngIf="levelId">Sector</a>
          <a routerLink="/tocBuilder/index" mat-button class="secondaryMenuLink" [routerLinkActive]="'active'">ToC Builder</a>
          <a routerLink="/indicators" mat-button class="secondaryMenuLink" [routerLinkActive]="'active'">Indicator Bank</a>
          <a routerLink="/questions" mat-button class="secondaryMenuLink" [routerLinkActive]="'active'">Question Bank</a>
          <a routerLink="/survey-builder/mySurvey" mat-button class="secondaryMenuLink" [routerLinkActive]="'active'">Survey Builder</a>
          <a routerLink="/users" mat-button class="secondaryMenuLink" [routerLinkActive]="'active'" *ngIf="levelId">Users</a>
      </div>
      </div>

      </mat-toolbar> -->

<div class="container-fluid mt-3">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 mainDiv">
                <div class="row" style="margin: 0 24px 54px 24px">
                    <div class="col-sm-4">
                        <img width="270" height="79.2" src="https://guide.idinsight.org/wp-content/uploads/2021/09/img.png" class="
            image
            wp-image-6247
            attachment-full
            size-full
            drdt-ignore-dark
          " alt="" loading="lazy" style="max-width: 100%; height: auto" />
                        <!-- <mat-icon aria-hidden="false" aria-label="polygon icon">
                      <img src="../../../../assets/Polygon 19.svg" alt="" width="21px">
                  </mat-icon>
                  <span class="head">Impact Measurement Guide</span> -->
                    </div>
                    <div class="col-sm-4" style="padding-top: 1.4px; padding-left: 210px">
                        <h3>Information</h3>
                        <h6><a href="https://guide.idinsight.org/about/">About</a></h6>
                        <h6>
                            <a href="https://guide.idinsight.org/terms-and-conditions/">Terms and conditions</a
          >
        </h6>
        <h6>
          <a href="https://guide.idinsight.org/privacy-policy/"
            >Privacy Policy</a
          >
        </h6>
      </div>
      <div class="col-sm-4" style="padding-top: 1.4px; padding-left: 108px">
        <h3>Contact Us</h3>
        <!-- <h6><a href="mailto:IMG@IDinsight.org">IMG@IDinsight.org</a></h6> -->
                        <h6>
                            <a href="mailto:IMG@IDinsight.org" style="font-size: 18px; color: #472666">guide@idinsight.org</a
          >
        </h6>
      </div>
    </div>
    <!-- <hr style="width: 50%; margin-left: 25%;"> -->
    <div class="row" style="margin: 1.5rem 22px 0">
      <div class="col-sm-4">
        <p>© 2021 IDInsight. All rights reserved.</p>
      </div>
      <div class="col-sm-8"></div>
      <!-- <div class="col-sm-4" style="padding-left: 100px">
        <p>
          Supported by the
          <a
            href="https://mulagofoundation.org/"
            style="text-decoration: none; color: #6754e2"
            >Mulago Foundation</a
          >
        </p>
      </div> -->
    </div>
  </div>
</div>
  </div>

</div>
import { HttpClient, HttpParams } from '@angular/common/http';
import { baseUrl } from '../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SigninService {
  constructor(private http: HttpClient) {}

  checkEmail(body) {
    return this.http.post(`${baseUrl}/otps/sendEmailOtp`, body);
  }
  checkOtp(body) {
    return this.http.post(`${baseUrl}/otps/verifyOtp`, body);
  }
  updateUser(userId, body) {
    return this.http.put(`${baseUrl}/users/${userId}`, body);
  }

  checkProfile(email) {
    return this.http.get(`${baseUrl}/user-sector-mappers/checkDetails?email=${email}`);
  }

  getTocUrl(userId) {
    return this.http.get(`${baseUrl}/theory-of-change-builders/tocUrl?userId=${userId}`);
  }
}

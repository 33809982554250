import { HttpClient, HttpParams } from '@angular/common/http';
import { baseUrl } from '../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RegService {
  constructor(private http: HttpClient) {}

  getUser(id) {
    return this.http.get(`${baseUrl}/users/${id}`);
  }

  updateUser(body, id) {
    return this.http.put(`${baseUrl}/users/${id}`, body);
  }

  authUser(body) {
    return this.http.post(`${baseUrl}/auth/local`, body);
  }

  getCountryList() {
    return this.http.get(`${baseUrl}/Countries?_limit=-1`);
  }

  getRegionAndSector(userId) {
    return this.http.get(`${baseUrl}/user-region-sector-mappers/${userId}`);
  }

  deleteSectorMapper(sectorId,userId){
    return this.http.delete(`${baseUrl}/user-sector-mappers?sectorId=${sectorId}&userId=${userId}`);
  }

  deleteRegionMapper(regionId,userId){
    return this.http.delete(`${baseUrl}/user-region-mappers?regionId=${regionId}&userId=${userId}`);
  }
}

<section class="mHed mb-3">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h1 style="padding-top: 3rem;">
                    {{header}}
                </h1>
            </div>
        </div>
    </div>
</section>
<div class="container">
    <div class="row">
        <div class="col-md-12 mb-3">
            <nav id="breadcrumbs">
                <span>
                 <span><a href="https://guide.idinsight.org/" class="b-link">Home</a> »
                        <span>
                          <a href="https://guide.idinsight.org/method/" class="b-link">Method</a> »
                          <span>
                            <a class="b-link" [routerLink]="['createSurvey']" routerLinkActive="active"> Survey Builder</a> »
                            <span class="breadcrumb_last b-link" aria-current="page">{{header}}</span></span>
                </span>
                </span>
                </span>
            </nav>
        </div>
    </div>
    <div class="row">

        <div class="col-sm-2">
            <!-- [ngClass]="{'active': active}" (click)="getMySubbmitedResult()" -->
            <a class="btn  searchFilters mb-3" [routerLink]="['mySurvey']" routerLinkActive="active">
                <div class="row">
                    <div class="col-md-11 btnName">
                        My Survey
                    </div>
                    <div class="col-md-1" style="padding-left: 0;">
                        <mat-icon style="float: right; margin-top: 2px; font-size: 14px;">arrow_forward_ios</mat-icon>
                    </div>
                </div>
            </a>
            <!-- <a class="btn  searchFilters mb-3" (click)="allSurveyResult()" [ngClass]="{'active': activeAllSurvey}">
                <div class="row">
                    <div class="col-md-11 btnName">
                        All Survey
                    </div>
                    <div class="col-md-1" style="padding-left: 0;">
                        <mat-icon style="float: right; margin-top: 2px; font-size: 14px;">arrow_forward_ios</mat-icon>
                    </div>
                </div>
            </a> -->
            <a class="btn searchFilters mb-3" [routerLink]="['questionBank']" routerLinkActive="active">
                <div class="row">
                    <div class="col-md-11 btnName">
                        Question Bank
                    </div>
                    <div class="col-md-1" style="padding-left: 0;">
                        <mat-icon style="float: right; margin-top: 2px; font-size: 14px;">arrow_forward_ios</mat-icon>
                    </div>
                </div>
            </a>
            <a class="btn  searchFilters mb-3" [routerLink]="['createSurvey']" routerLinkActive="active">
                <div class="row">
                    <div class="col-md-11 btnName">
                        Create New Survey
                    </div>
                    <div class="col-md-1" style="padding-left: 0;">
                        <mat-icon style="float: right; margin-top: 2px; font-size: 14px;">arrow_forward_ios</mat-icon>
                    </div>
                </div>
            </a>

            <hr>
            <div *ngIf="showSurveyFilter">
                <!-- <div class="col-sm-11 col-md-11 col-lg-11" style="padding: 1% 0; color: #161341;"> -->
                <label>Sector</label>
                <select class="form-select mb-3" aria-label="Default select example" #selectedSurvey>
                    <option [value]="">Select</option>
                    <option *ngFor="let item of sectorList; let j=index;" [value]="item.id">{{item.name}}</option>
                </select>
                <!-- </div> -->
                <label>Survey Name</label>
                <input type="text" class="form-control mb-3" placeholder="Type here..." (keyup)="filterName($event,'survey')">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="d-grid"><button class="btn btn-reset" (click)="filterSector(selectedSurvey.value,'survey')">Search</button></div>
                    </div>
                    <div class="col-sm-6">
                        <div class="d-grid"><button type="button" class="btn btn-reset" (click)="resetFilter()">Reset</button></div>
                    </div>
                </div>
            </div>
            <div *ngIf="showQuestionFilter">
                <!-- <div class="col-sm-11 col-md-11 col-lg-11" style="padding: 1% 0; color: #161341;"> -->
                <label>Response Type</label>
                <select class="form-select mb-3" aria-label="Default select example" id="type" #selectedType>
                  <option [value]="" >Select</option>
                  <option *ngFor="let qus of questionType" [value]="qus.key">{{qus.value}}</option>
              </select>
                <!-- </div> -->
                <label>Question Name</label>
                <input type="text" class="form-control mb-3" placeholder="Type here..." (keyup)="filterName($event,'question')">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="d-grid"><button class="btn btn-reset" (click)="filterSector(selectedType.value,'question')">Search</button></div>
                    </div>
                    <div class="col-sm-6">
                        <div class="d-grid"><button type="button" class="btn btn-reset" (click)="resetFilter()">Reset</button></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-10" style="padding: 0 1.5rem;">
            <router-outlet></router-outlet>
        </div>

    </div>
</div>
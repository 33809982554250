import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseUrl } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LandingPageService {

  constructor(private http:HttpClient) { }

  loadSectorList(){
    return this.http.get(`${baseUrl}/sectors?_sort=updated_at:desc`);
  }

  createForm(data){
    return this.http.post(`${baseUrl}/theory-of-change-builders`,data);
  }

  updateForm(id,data){
    return this.http.put(`${baseUrl}/theory-of-change-builders/update/${id}`,data);
  }

  updateUser(data){
    return this.http.post(`${baseUrl}/theory-of-change-builders/update-user`,data);
  }

  loadTocList(id){
    return this.http.get(`${baseUrl}/theory-of-change-builders/${id}`);
  }

  loadTocOfUser(userId){
    return this.http.get(`${baseUrl}/theory-of-change-builders/user-wise/${userId}`);
  }

  indicatorMapperForm(data){
    return this.http.post(`${baseUrl}/toc-indicator-mappers/createUpdate`,data);
  }

  indicatorMapperDelete(id){
    return this.http.delete(`${baseUrl}/toc-indicator-mappers/${id}`);
  }

  indicatorMapperList(filterObj){
    return this.http.post(`${baseUrl}/toc-indicator-mappers/list`,filterObj);
  }

  deleteIndicatorMapperRow(id){
    return this.http.delete(`${baseUrl}/toc-indicator-mappers/${id}`);
  }

  deleteTocList(id){
    return this.http.delete(`${baseUrl}/theory-of-change-builders/${id}`);
  }

  deleteTocOfUser(userId){
    return this.http.delete(`${baseUrl}/theory-of-change-builders/user-wise-delete/${userId}`);
  }
  
  encodeDecode(data){
    return this.http.post(`${baseUrl}/toc-indicator-mappers/encodeDecode`,data);
  }

}

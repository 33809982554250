import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseUrl } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SurveyServicesService {

  private filterSource = new BehaviorSubject<object>({});
  currentFilter = this.filterSource.asObservable();
  constructor(
    private http:HttpClient
  ) { }

  useFilter(obj){
    this.filterSource.next(obj);
  }
  
  surveyPost(data){
    return this.http.post(`${baseUrl}/survey-builders`, data);
  }

  surveyUpdate(data,id){
    return this.http.put(`${baseUrl}/survey-builders/${id}`, data);
  }

  getMySurvey(obj){
    obj['_sort'] =  'updated_at:desc';
    return this.http.post(`${baseUrl}/survey-builders/findAll`,obj);
  }

  deleteSurvey(id){
    return this.http.delete(`${baseUrl}/survey-builders/${id}`);
  }

  findOne(id){
    return this.http.get(`${baseUrl}/survey-builders/findOne/${id}`);
  }

  updateSurveyForm(id,data){
    return this.http.put(`${baseUrl}/survey-builders/${id}`,data);
  }
}

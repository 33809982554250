import { Component, OnInit, AfterViewInit } from '@angular/core'
import * as MindFusion from 'diagram-library';
import Diagram = MindFusion.Diagramming.Diagram;
import CompositeNode = MindFusion.Diagramming.CompositeNode;
import Behavior = MindFusion.Diagramming.Behavior;
import Rect = MindFusion.Drawing.Rect;

var OrgChartNode = CompositeNode.classFromTemplate("OrgChartNode", {
    component: "GridPanel",
    rowDefinitions: ["*"],
    columnDefinitions: ["22", "*"],
    children:
        [
            {
                component: "Rect",
                name: "Background",
                pen: "black",
                brush: "white",
                columnSpan: 2
            },
            {
                component: "StackPanel",
                // orientation: "Vertical",
                // gridColumn: 1,
                margin: "2",
                // verticalAlignment: "Near",
                children:
                    [
                        {
                            component: "Text",
                            name: "Title",
                            autoProperty: true,
                            text: "title",
                            font: "Arial bold",
                            pen: "blue",
                            padding: "1,0,1,0"
                        }
                    ]
            }
        ]
});

@Component({
    selector: 'app-chart',
    templateUrl: './chart.component.html',
    styleUrls: ['./chart.component.css']
})
export class ChartComponent implements OnInit, AfterViewInit {

    diagram: Diagram;
    behavior: Behavior = Behavior.Custom;

    ngOnInit(): void {
        // create diagram 
        this.diagram = new Diagram();

    }

    ngAfterViewInit(): void {

        // set diagram bounds
        this.diagram.setBounds(new Rect(0, 0, 295, 200));
        this.diagram.setCustomNodeType(OrgChartNode);

        // start creating nodes
        var ceo_node = new OrgChartNode(this.diagram);
        ceo_node.setBounds(new Rect(25, 15, 60, 25));
        ceo_node.setTitle("CEO");
        ceo_node.setTextColor("red");
        this.diagram.addItem(ceo_node);

        var cto_node = new OrgChartNode(this.diagram);
        cto_node.setBounds(new Rect(25, 55, 60, 25));
        cto_node.setTitle("CTO");
        this.diagram.addItem(cto_node);

        var hr_node = new OrgChartNode(this.diagram);
        hr_node.setBounds(new Rect(95, 55, 60, 25));
        hr_node.setTitle("HR");
        this.diagram.addItem(hr_node);

        var pr_node = new OrgChartNode(this.diagram);
        pr_node.setBounds(new Rect(175, 55, 60, 25));
        pr_node.setTitle("PR");
        this.diagram.addItem(pr_node);

        var media_node = new OrgChartNode(this.diagram);
        media_node.setBounds(new Rect(175, 95, 60, 25));
        media_node.setTitle("Media");
        this.diagram.addItem(media_node);

        // connect nodes
        this.diagram.getFactory().createDiagramLink(ceo_node, cto_node);
        this.diagram.getFactory().createDiagramLink(ceo_node, hr_node);
        this.diagram.getFactory().createDiagramLink(ceo_node, pr_node);
        this.diagram.getFactory().createDiagramLink(pr_node, media_node);
        this.diagram.getFactory().createDiagramLink(hr_node, media_node);
        

    }


    getImage(imageName): string {
        return '../assets/' + imageName;
    }
}
<div class="container">
    <button id="startTourBtn" class="btn btn-primary">Start Tour</button>
    <h2>Panel Group</h2>
    <p>The panel-group class clears the bottom-margin. Try to remove the class and see what happens.</p>
    <div class="panel-group">
        <div class="panel panel-default" id="panelA">
            <div class="panel-heading">Panel Header</div>
            <div class="panel-body">Panel Content</div>
        </div>
        <div class="panel panel-default" id="panelB">
            <div class="panel-heading">Panel Header</div>
            <div class="panel-body">Panel Content</div>
        </div>
        <div class="panel panel-default" id="panelC">
            <div class="panel-heading">Panel Header</div>
            <div class="panel-body">Panel Content</div>
        </div>
    </div>
</div>

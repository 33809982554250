import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { baseUrl } from 'src/environments/environment';
import { UpdateProfileComponent } from '../update-profile/update-profile.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SigninService } from '../sign-in/signin.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.css']
})
export class SocialMediaComponent implements OnInit {
  id_token : string;
  access_token : string
  loginContinue : boolean;
  Error : string = '';
  localData;
  constructor(private route : ActivatedRoute,
    private http:HttpClient,
    private authService: AuthService,
    private dialog : MatDialog,
    private signInService : SigninService,
    private cookieService: CookieService
    ) { }

  ngOnInit(): void {
    this.loginContinue = true;
    this.route.queryParams.subscribe(params => {
      this.id_token = params['id_token'];
      this.access_token = params['access_token'];
    });

    this.authService.googleSignIn(this.id_token,this.access_token).subscribe((data:any) => {
      if(data){
        console.log('google data', data);
        let currentUser = JSON.stringify(this.authService.currentUserValue.user);
        this.cookieService.set(
          'user',
          currentUser,
          100,
          '/',
          '.idinsight.org'
        );  
        this.cookieService.set(
          'isLoggedIn',
          "true",
          100,
          '/',
          '.idinsight.org'
        );
        this.cookieService.set(
          'userId',
          this.authService.currentUserValue.user.id,
          100,
          '/',
          '.idinsight.org'
        );

        setTimeout(()=>{
        // window.location.href = '/tocBuilder/index';
         console.log('google data', data);
         this.localData = JSON.parse(localStorage.getItem('currentUser'))
         console.log('l2 data', this.localData, this.localData.user.email);
         let userEmail = this.localData.user.email;
         this.signInService.checkProfile(userEmail).subscribe((res)=> {
          console.log('check res', res);
          if(res == false){
            this.openDialog(this.localData);
          }else{
            window.location.href = '/tocBuilder/index';
          }

         },
         (err)=>{
           alert('Something went wrong..')
         }
         )

         // this.openDialog();
        }, 3000);
      }
    },
    (error) => {
      this.loginContinue = false;
      this.Error = error.error.message[0].messages[0].message;
    })
  }
  openDialog(userdata){
    // this.dialog.open(TocDialogComponent)
    console.log('dia', userdata);

     const dialogRef = this.dialog.open(UpdateProfileComponent, {
      data: userdata,
      disableClose: true,
      width: '95%',
      height: '95%',

    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });

  }
}

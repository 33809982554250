import { Component, OnInit } from '@angular/core';
import * as go from 'gojs';
import { CookieService } from 'ngx-cookie-service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UUID } from 'angular2-uuid';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'angular-basic';
  public selectedNode = null;
  diagramUrl =
    'https://cdn.staticaly.com/gh/bpmn-io/bpmn-js-examples/dfceecba/starter/diagram.bpmn';
  importError?: Error;

  public model: go.TreeModel = new go.TreeModel([
    { key: 1, name: 'Stella Payne Diaz', title: 'CEO' },
    { key: 2, name: 'Luke Warm', title: 'VP Marketing/Sales', parent: 1 },
    { key: 3, name: 'Meg Meehan Hoffa', title: 'Sales', parent: 2 },
    { key: 4, name: 'Peggy Flaming', title: 'VP Engineering', parent: 1 },
    { key: 5, name: 'Saul Wellingood', title: 'Manufacturing', parent: 4 },
    { key: 6, name: 'Al Ligori', title: 'Marketing', parent: 2 },
    { key: 7, name: 'Dot Stubadd', title: 'Sales Rep', parent: 3 },
    { key: 8, name: 'Les Ismore', title: 'Project Mgr', parent: 5 },
    { key: 9, name: 'April Lynn Parris', title: 'Events Mgr', parent: 6 },
    { key: 10, name: 'Xavier Breath', title: 'Engineering', parent: 4 },
    { key: 11, name: 'Anita Hammer', title: 'Process', parent: 5 },
    { key: 12, name: 'Billy Aiken', title: 'Software', parent: 10 },
    { key: 13, name: 'Stan Wellback', title: 'Testing', parent: 10 },
    { key: 14, name: 'Marge Innovera', title: 'Hardware', parent: 10 },
    { key: 15, name: 'Evan Elpus', title: 'Quality', parent: 5 },
    { key: 16, name: 'Lotta B. Essen', title: 'Sales Rep', parent: 3 },
  ]);

  uuidValue: string;
  userIpInfo;

  constructor(
    private cookieService: CookieService,
    private deviceDetectorService: DeviceDetectorService,
    private authService: AuthService
  ) {
    this.uuidValue = UUID.UUID();
  }

  ngOnInit() {
    this.setCookie();
  }

  setCookie() {
    this.authService.userIp().subscribe(
      (res) => {
        this.userIpInfo = res;
      },
      (error) => {
        console.log(error);
      }
    );
    if (!this.cookieService.check('uniqueUserId')) {
      this.cookieService.set('uniqueUserId', this.uuidValue);
      this.saveUserData();
    } else {
      //update time
      this.authService
        .checkUniqueId(this.cookieService.get('uniqueUserId'))
        .subscribe(
          (res) => {
            if (res['error']) {
              this.cookieService.set('uniqueUserId', this.uuidValue);
              this.saveUserData();
              return;
            }
            this.authService
              .updateUniqueUser({ lastVisit: new Date() }, res['id'])
              .subscribe(
                (res) => {
                  // console.log(res);
                },
                (error) => {}
              );
          },
          (error) => {
            this.cookieService.set('uniqueUserId', this.uuidValue);
            this.saveUserData();
          }
        );
    }
  }

  saveUserData() {
    let body = {
      Extras: null,
      os: null,
      cookie: null,
      browser: null,
      isMobile: null,
      ipAddress: null,
      isDesktop: null,
      lastVisit: new Date(),
      firstVisit: new Date(),
    };

    body.browser = this.deviceDetectorService.browser;
    body.os = this.deviceDetectorService.os;
    body.cookie = this.uuidValue;
    body.isDesktop = this.deviceDetectorService.isDesktop();
    body.isMobile = this.deviceDetectorService.isMobile();
    body.ipAddress = this.userIpInfo?.ip ? this.userIpInfo.ip : null;
    body.Extras = { device: this.deviceDetectorService, ip: this.userIpInfo };

    this.authService.uniqueUser(body).subscribe(
      (res) => {},
      (err) => {}
    );
  }

  handleImported(event) {
    const { type, error, warnings } = event;

    if (type === 'success') {
      console.log(`Rendered diagram (%s warnings)`, warnings.length);
    }

    if (type === 'error') {
      console.error('Failed to render diagram', error);
    }

    this.importError = error;
  }

  public setSelectedNode(node) {
    this.selectedNode = node;
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { ChartComponent } from './chart/chart.component';
import { EmailComponent } from './components/email/email.component';
import { HomeComponent } from './components/home/home.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignOutComponent } from './components/sign-out/sign-out.component';
import { SocialMediaComponent } from './components/social-media/social-media.component';
import { TourComponent } from './components/tour/tour.component';
import { DrawioComponent } from './drawio/drawio.component';
import { IndicatorModule } from './indicator/indicator.module';
import { QuestionModule } from './question/question.module';
import { SectorModule } from './sector/sector.module';
import { SecurityGuardGuard } from './security-guard.guard';
import { UserModule } from './user/user.module';

// canActivate: [AuthGuard]
const routes: Routes = [
  // {
  //   path:"xyz",
  //   component:TourComponent,
  //   pathMatch:"full",
  //   // canActivate: [SecurityGuardGuard]
  // },
  {
    path:"connect/google/redirect",
    component:SocialMediaComponent,
    pathMatch:"full"
  },
  {
    path:"",
    component:HomeComponent,
    pathMatch:"full",
    canActivate: [SecurityGuardGuard]
  },
  {
    path:"draw",
    component:DrawioComponent,
    pathMatch:"full"
  },
  {
    path:"sign-out",
    component:SignOutComponent,
    pathMatch:"full"
  },
  {
    path:"sign-in",
    component:SignInComponent,
    pathMatch:"full"
  },
  {
    path:"sign-up",
    component:RegistrationComponent,
    pathMatch:"full"
  },
  {
    path:"indicators",
    loadChildren: "./indicator/indicator.module#IndicatorModule",
    canActivate: [SecurityGuardGuard]
  },
  {
    path:"create",
    loadChildren: "./indicator/indicator.module#IndicatorModule",
    // canActivate: [SecurityGuardGuard]
  },
  {
    path:"update/:id",
    loadChildren: "./indicator/indicator.module#IndicatorModule",
    // canActivate: [SecurityGuardGuard]
  },
  {
    path:"sector",
    loadChildren: "./sector/sector.module#SectorModule",
    canActivate: [SecurityGuardGuard]
  },
  {
    path:"create",
    loadChildren: "./sector/sector.module#SectorModule",
    // canActivate: [SecurityGuardGuard]
  },
  {
    path:"update/:id",
    loadChildren: "./sector/sector.module#SectorModule",
    // canActivate: [SecurityGuardGuard]
  },
  {
    path:"questions",
    loadChildren: "./question/question.module#QuestionModule",
    canActivate: [SecurityGuardGuard]
  },
  {
    path:"create",
    loadChildren: "./question/question.module#QuestionModule",
    // canActivate: [SecurityGuardGuard]
  },
  {
    path:"update/:id",
    loadChildren: "./question/question.module#QuestionModule",
    // canActivate: [SecurityGuardGuard]
  },
  {
    path:"users",
    loadChildren: "./user/user.module#UserModule",
    canActivate: [SecurityGuardGuard]
  },
  {
    path: "homePage",
    loadChildren: () =>
      import("./pages/pages.module").then(
        (m) => m.PagesModule
      ),
  },
  {
    path: "tocBuilder",
    loadChildren: () =>
      import("./toc-builder/toc-builder.module").then(
        (m) => m.TocBuilderModule
      ),
    canActivate: [SecurityGuardGuard]
  },
  {
    path: "survey-builder",
    loadChildren: () =>
      import("./survey-builder/survey-builder.module").then(
        (m) => m.SurveyBuilderModule
      ),
      canActivate: [SecurityGuardGuard]
  },
  {
    path: "admin-dashboard",
    loadChildren: () =>
      import("./admin/admin.module").then(
        (m) => m.AdminModule
      ),
    canActivate: [SecurityGuardGuard]
  },
  {
    path: "user-dashboard",
    loadChildren: () =>
      import("./user-dashboard/user-dashboard.module").then(
        (m) => m.UserDashboardModule
      ),
    canActivate: [SecurityGuardGuard]
  },

  // {path: 'tutorial3', component: ChartComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

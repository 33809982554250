import { Component, OnInit } from '@angular/core';
import { SocialAuthService } from 'angularx-social-login';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-sign-out',
  templateUrl: './sign-out.component.html',
  styleUrls: ['./sign-out.component.css']
})
export class SignOutComponent implements OnInit {

  constructor(private authService: SocialAuthService,private cookieService: CookieService) { }

  ngOnInit(): void {
    this.authService.signOut();
    localStorage.clear();
    this.cookieService.delete("tocUrl","/",".idinsight.org")
    this.cookieService.delete("user","/",".idinsight.org")
    this.cookieService.set(
      'isLoggedIn',
      "false",100,
      '/',
      '.idinsight.org'
    );
    this.cookieService.set(
      'userId',
      "null",100,
      '/',
      '.idinsight.org'
    );
    window.location.href = '/sign-in'
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseUrl } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SectorService {

  constructor(private http: HttpClient) { }

  listSector(){
    return this.http.get(`${baseUrl}/sectors?_sort=updated_at:desc`);
  }

  deleteList(id){
    return this.http.delete(`${baseUrl}/sectors/${id}`);
  }

  findOne(id){
    return this.http.get(`${baseUrl}/sectors/${id}`);
  }

  createForm(data){
    return this.http.post(`${baseUrl}/sectors`,data);
  }

  updateForm(id,data){
    return this.http.put(`${baseUrl}/sectors/${id}`,data);
  }
}

<div class="container">
    <div class="row headDiv" style="padding: 2%;">
        <div class="col-md-12 text-end" style="margin-top: -12px;">
            <a (click)="dialogClose()" style="cursor: pointer;">
                <span class="material-icons" style="font-size: 24px;
            font-weight: bolder;">
           clear
      </span>
            </a>
        </div>
        <p class="head">Node Instructions</p>
    </div>

    <div class="row">
        <div class="col-md-12 p-0">
            <div class="card mt-2">
                <div class="card-body">
                    <span class="n-h">Inputs:</span>&nbsp;
                    <span class="ins">
                    What resources will you need to deliver your program? For example, staffing,
                    infrastructure, materials, facilities, and sources of financing.
                   </span>

                    <div class="col-div" id="collapseEvent" [isAnimated]="true" [collapse]="isCollapsedInput" (collapsed)="collapsed('input')" (expanded)="expanded('input')">
                        <span class="ins">
                      Use as many boxes and connections as you need to accurately and usefully describe your
                      program! It helps to be as specific as possible! How many staff members do you think you will
                       need? How much money? Remember, you can always update it later!
                     </span>
                    </div>
                    <a type="button" class="read-more" (click)="isCollapsedInput = !isCollapsedInput" [attr.aria-expanded]="!isCollapsedInput" aria-controls="collapseEvent">{{inputBtnName}}
                      </a>
                </div>
            </div>
            <div class="card mt-2">
                <div class="card-body">
                    <span class="n-h">Activities:</span>&nbsp;
                    <span class="ins">
                        Most programs rely on a sequence of events to conduct their main activity. Break down your
                        single activity into individual steps. For example, “Offer remedial classes”, can be broken down into
                         “Curriculum team develops remedial content,” “Training team develops staff training content,” “ training team
                          trains teaching staff,”
                         “School liaisons identify students needing remedial support,”Teaching staff offer remedial classes to students.
                      </span>

                    <div class="col-div" id="collapseEvent" [isAnimated]="true" [collapse]="isCollapsedActi" (collapsed)="collapsed('acti')" (expanded)="expanded('acti')">
                        <span class="ins">
                            Breaking down your theory of change into small steps with specificity like this one will help you later on - for example, it makes it easier to identify the assumption your program is making! Use as many "Activity" boxes as you need.
                   </span>
                    </div>
                    <a type="button" class="read-more" (click)="isCollapsedActi = !isCollapsedActi" [attr.aria-expanded]="!isCollapsedActi" aria-controls="collapseEvent">{{actiBtnName}}
                    </a>
                </div>
            </div>
            <div class="card mt-2">
                <div class="card-body">
                    <span class="n-h">Outputs:</span>&nbsp;
                    <span class="ins">
                    Add the products or services or milestones directly stemming from your program as output.
                     Output nodes often repeat
                    the prior activity node as a completed milestone. For example, remedial classes are conducted.
                    </span>

                    <div class="col-div" id="collapseEvent" [isAnimated]="true" [collapse]="isCollapsedOutput" (collapsed)="collapsed('output')" (expanded)="expanded('output')">
                        <span class="ins">
                        You can identify outputs by working forward from your Activities or
                        backwards from your Outcomes,
                         and add the missing links between the Activities and the Outcomes.
                 </span>
                    </div>
                    <a type="button" class="read-more" (click)="isCollapsedOutput = !isCollapsedOutput" [attr.aria-expanded]="!isCollapsedOutput" aria-controls="collapseEvent">{{outputBtnName}}
                  </a>
                </div>
            </div>
            <div class="card mt-2">
                <div class="card-body">
                    <span class="n-h">Outcomes:</span>&nbsp;
                    <span class="ins">
                  A program often produces many linked outcomes as a product of program outputs. For example,
                   because literacy classes are conducted (your output), "Students attend literacy classes,
                   students may participate in learning activities, and “literacy improves” .
                  </span>

                    <div class="col-div" id="collapseEvent" [isAnimated]="true" [collapse]="isCollapsedOutcom" (collapsed)="collapsed('outCom')" (expanded)="expanded('outCom')">
                        <span class="ins">

                      </span>
                    </div>
                    <a type="button" class="read-more" (click)="isCollapsedOutcom = !isCollapsedOutcom" [attr.aria-expanded]="!isCollapsedOutcom" aria-controls="collapseEvent">{{outComBtnName}}
                </a>
                </div>
            </div>
        </div>

    </div>
</div>

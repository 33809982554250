import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private snack:MatSnackBar,
    private router : Router) {
      this.router.navigate(['admin-dashboard'])
  }

  ngOnInit(): void {
  }

  btnClick(){
    console.log("btn click");
    this.snack.open("Hey Welcome","cancel")
  }

}

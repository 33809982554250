<div class="row">
    <div class="col-md-12" style="padding-left: 5%; padding-top: 1%;">
        <a class="btn btnBk" (click)="goBack()">BACK
            <!-- <span class="material-icons">
              arrow_back
             </span> -->
        </a>
    </div>
</div>

<div class="card">
    <mat-card>
        <form class="example-form" [formGroup]="signInFormGroup">
            <table class="example-full-width" cellspacing="0">
                <tr>
                    <td>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>First Name<i style="color: red; font-size: 16px;">*</i> </mat-label>
                            <input matInput formControlName="firstName">
                            <mat-icon matSuffix style="cursor: pointer;" matTooltip="First name is mandatory">info
                            </mat-icon>
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Last Name<i style="color: red; font-size: 16px;">*</i></mat-label>
                            <input matInput formControlName="lastName">
                            <mat-icon matSuffix style="cursor: pointer;" matTooltip="Last name is mandatory">info
                            </mat-icon>
                        </mat-form-field>
                    </td>
                </tr>
                <tr *ngIf="updateProfile">
                    <td>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Username<i style="color: red; font-size: 16px;">*</i></mat-label>
                            <input matInput formControlName="username">
                            <mat-icon matSuffix style="cursor: pointer;" matTooltip="User name is mandatory">info
                            </mat-icon>
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Email Id<i style="color: red; font-size: 16px;">*</i></mat-label>
                            <input type="email" placeholder="Ex. pat@example.com" matInput formControlName="email">
                            <mat-icon matSuffix style="cursor: pointer;" matTooltip="Email is mandatory and should be like pat@example.com">info</mat-icon>
                            <mat-hint class="text-danger" *ngIf="signInFormGroup.get('email').invalid">
                                Email is mandatory and should be like pat@example.com
                            </mat-hint>
                        </mat-form-field>
                    </td>
                </tr>
                <tr *ngIf="!updateProfile">
                    <td>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Username<i style="color: red; font-size: 16px;">*</i></mat-label>
                            <input matInput formControlName="username">
                            <mat-icon matSuffix style="cursor: pointer;" matTooltip="User name is mandatory">info
                            </mat-icon>
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Email Id<i style="color: red; font-size: 16px;">*</i></mat-label>
                            <input type="email" placeholder="Ex. pat@example.com" matInput formControlName="email">
                            <mat-icon matSuffix style="cursor: pointer;" matTooltip="Email is mandatory and should be like pat@example.com">info</mat-icon>
                            <mat-hint class="text-danger" *ngIf="signInFormGroup.get('email').invalid">
                                Email is mandatory and should be like pat@example.com
                            </mat-hint>
                        </mat-form-field>

                    </td>
                </tr>
                <tr *ngIf="!updateProfile">
                    <td>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Password<i style="color: red; font-size: 16px;">*</i></mat-label>
                            <input matInput type="password" formControlName="password">
                            <mat-icon matSuffix style="cursor: pointer;" matTooltip="Password must be at least 6 characters.">info</mat-icon>
                            <mat-hint class="text-danger" *ngIf="signInFormGroup.get('password').hasError('minlength')">
                                Password must be at least 6 characters.</mat-hint>
                        </mat-form-field>

                    </td>
                    <td>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Confirm Password<i style="color: red; font-size: 16px;">*</i></mat-label>
                            <input matInput type="password" formControlName="confirmPassword">
                            <!-- <small class="text-danger" *ngIf="signInFormGroup.get('confirmPassword').hasError('mustMatch')">
                           Passwords and Confirm Password must match.</small> -->
                            <mat-icon matSuffix style="cursor: pointer;" matTooltip="Password and Confirm Password must be same">info</mat-icon>
                            <mat-hint class="text-danger" *ngIf="signInFormGroup.get('confirmPassword').hasError('mustMatch')">
                                Password and Confirm Password must match.
                            </mat-hint>

                        </mat-form-field>
                    </td>
                </tr>
                <tr>
                    <td>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>About how many people does your program reach?</mat-label>
                            <input matInput formControlName="programReach">
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Organization Type<i style="color: red; font-size: 16px;">*</i></mat-label>
                            <mat-select formControlName="organizationType">
                                <mat-option>None</mat-option>
                                <mat-option value="NGO">NGO</mat-option>
                                <mat-option value="CSR">CSR</mat-option>
                                <mat-option value="Goverment Agency">Goverment Agency</mat-option>
                                <mat-option value="FPO">FPO</mat-option>
                                <mat-option value="Foundation">Foundation</mat-option>
                                <mat-option value="Others">Others</mat-option>
                            </mat-select>
                            <mat-icon matSuffix style="cursor: pointer;" matTooltip="Organization Type is mandatory">info
                            </mat-icon>
                        </mat-form-field>
                    </td>
                </tr>
                <tr>
                    <td>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Organization Name</mat-label>
                            <input matInput formControlName="organizationName">
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Region(s)</mat-label>
                            <mat-select multiple formControlName="region">
                                <mat-option *ngFor="let region of regionList" [value]="region.id">{{region.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                </tr>
                <tr>
                    <td>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Sector(s)</mat-label>
                            <mat-select multiple formControlName="sector">
                                <mat-option *ngFor="let sector of sectorList" [value]="sector.id">{{sector.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Website URL, if Any</mat-label>
                            <input matInput formControlName="websiteUrl">
                        </mat-form-field>
                    </td>
                </tr>
                <tr>
                    <td>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Country<i style="color: red; font-size: 16px;">*</i></mat-label>
                            <mat-select [(value)]="formValue.country" formControlName="country">
                                <mat-option *ngFor="let item of countryList" [value]="item.geoId">{{item.name}}
                                </mat-option>
                            </mat-select>
                            <mat-icon matSuffix style="cursor: pointer;" matTooltip="Country is mandatory">info
                            </mat-icon>
                        </mat-form-field>
                    </td>
                </tr>
                <tr>
                    <td>
                        <button type="submit" (click)="doSubmitForm()" class="btn btn btnCls">{{btnTxt}}</button>
                    </td>
                </tr>
            </table>
        </form>
    </mat-card>
    <div class="d-flex justify-content-center" *ngIf="loader">
        <div class="spinner-border text-success" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</div>

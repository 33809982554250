import { Component, Inject, OnInit } from '@angular/core';
import axios from 'axios';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CustomValidators } from 'src/app/services/custom_validators';
import { baseUrl } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { SweetAlert } from 'sweetalert/typings/core';
import { ActivatedRoute, Router } from '@angular/router';
const swal: SweetAlert = require('sweetalert');
// import { FormService } from './services/form_base';
import { RegService } from '../registration/reg.service';
import { SectorService } from 'src/app/sector/sector.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.css']
})
export class UpdateProfileComponent implements OnInit {

  title = 'Registration';
  registerUser = {};
  error = null;
  toppings = new FormControl();
  regionList: any;
  sectorList: any;
  btnTxt = 'Register';
  updateProfile = false;
  countryList = [];
  userId = JSON.parse(localStorage.getItem('currentUser'))?.user?.id ?? null;
  public signInFormGroup: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private regService: RegService,
    private loadSector: SectorService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.activatedRoute.queryParams.subscribe((param) => {
      if (param?.fromUpdate == 'true') {
        this.btnTxt = 'Update';
        this.updateProfile = true;
        this.getUserProfile(this.userId);
      }
    });
    this.regService.getCountryList().subscribe(
      (res: any) => {
        // console.log(res, 'Country List');
        this.countryList = res;
        this.countryList = sortByKey(this.countryList, 'name');
      },
      (error) => {}
    );
    // console.log('mat data', this.data, this.data.user.id);

    if (this.data) {
      this.btnTxt = 'Update';
      this.updateProfile = true;
      this.getUserProfile(this.data.user.id);
    }
  }

  get formValue() {
    return this.signInFormGroup.value;
  }

  initialSectorQues={}

  async ngOnInit() {
    this.regionList = [
      { id: '1', name: 'Africa' },
      { id: '2', name: 'Asia' },
      { id: '3', name: 'Europe' },
      { id: '4', name: 'Middle East and North Africa' },
      { id: '5', name: 'North America' },
      { id: '6', name: 'Latin America' },
      { id: '7', name: 'Oceania' },
    ];
    this.loadSector.listSector().subscribe((data) => {
      this.sectorList = data;
    });
    if (this.updateProfile) {
      this.signInFormGroup = this.formBuilder.group(
        {
          firstName: ['',Validators.required],
          lastName: ['',Validators.required],
          email: ['', [Validators.required, Validators.email]],
          username: ['', Validators.required],
          password: [''],
          confirmPassword: [''],
          programReach: [''],
          organizationType: ['',Validators.required],
          organizationName: [],
          region: [],
          sector: [],
          websiteUrl: [],
          country: ['', Validators.required],
        },
        {
          validator: CustomValidators.MustMatch('password', 'confirmPassword'),
        }
      );
    } else {
      this.signInFormGroup = this.formBuilder.group(
        {
          firstName: ['', Validators.required],
          lastName: ['', Validators.required],
          email: ['', [Validators.required, Validators.email]],
          username: ['', Validators.required],
          password: ['', [Validators.required, Validators.minLength(6)]],
          confirmPassword: ['', Validators.required],
          programReach: [''],
          organizationType: [],
          organizationName: [],
          region: [],
          sector: [],
          websiteUrl: [],
          country: ['', Validators.required],
        },
        {
          validator: CustomValidators.MustMatch('password', 'confirmPassword'),
        }
      );
    }
  }


  getUserProfile(id) {
    this.regService.getUser(id).subscribe(
      (res) => {
        res['region'] = Object.keys(res['user_region_mappers']).map(function(k){return res['user_region_mappers'][k]['regionId'].toString()});
        res['sector'] = Object.keys(res['user_sector_mappers']).map(function(k){return res['user_sector_mappers'][k]['sectorId']});
        this.signInFormGroup.patchValue(res);
        this.initialSectorQues['sector'] = res['sector']
        this.initialSectorQues['region'] = res['region']
      },
      (error) => {}
    );
  }
  async doSubmitForm() {
    //  console.log("region",this.signInFormGroup, this.signInFormGroup.value.region)

    if (this.signInFormGroup.status != 'INVALID') {
      if (this.updateProfile) {
        try {
          return await this.updateUser(this.userId);
        } catch (error) {
          return swal({
            title: "Alert!",
            text: 'Enter Correct Password to Continue',
            icon: 'error',
          });
        }
      }
      axios
        .post(`${baseUrl}/users`, this.signInFormGroup.value)
        .then(async (response) => {
          // console.log('region len..............',this.signInFormGroup.value.region);
          if (this.signInFormGroup.value.region) {
            await this.regionSectorMapper(response.data.id);
          }
          swal({
            title: 'Saved',
            text: 'You have register successfully.',
            icon: 'success',
          });
          this.signInFormGroup.reset();
          // console.log("response",response.data.id)
          // console.log("region",this.signInFormGroup.value.region)
          window.location.href = '/sign-in';
        })
        .catch((err) => {
          if (err.response) {
            // client received an error response (5xx, 4xx)
            //  console.log(err.response);

            swal({
              title: 'Error!',
              text: err.response.data.message[0].messages[0].message,
              icon: 'error',
            });
          } else if (err.request) {
            // client never received a response, or request never left
            //  console.log(err.response);
            swal({
              title: 'Error!',
              text: 'Something went wrong please try again',
              icon: 'error',
            });
            console.log('err request', err.message);
          }
        });
    } else {
      swal({
        title: 'Alert!',
        text: 'Please fill all the mandatory details.',
        icon: 'error',
      });
    }
  }

  checkUser() {
    return new Promise((resolve, reject) => {
      let body = {
        identifier: this.signInFormGroup.value.username,
        password: this.signInFormGroup.value.password,
      };
      this.regService.authUser(body).subscribe(
        (res) => {
          this.updateUser(this.userId);
          resolve('success');
        },
        (error) => {
          reject('Incorrect Password');
        }
      );
    });
  }

  updateUser(id) {
    let body = this.signInFormGroup.value;
    for (const key in body) {
      const element = body[key];
      if (!element) {
        delete body[key];
      }
    }
    this.regService.updateUser(this.signInFormGroup.value, id).subscribe(
      async (res: any) => {
        // console.log(res, 'updated user data');
        if (this.signInFormGroup.value.region) {
          this.regionSectorMapper(this.userId);
        }
        await swal({
          title: 'Completed',
          text: 'Profile Updated',
          icon: 'success',
        });

        if(this.data){
          window.location.href = '/tocBuilder/index';
        }else{
          this.router.navigateByUrl('/tocBuilder/index');
          this.dialog.closeAll();
        }
      },
      (err) => {
        console.log(err);

        swal({
          title: 'Error!',
          text: err.error.message,
          icon: 'error',
        });
      }
    );
  }

  async regionSectorMapper(userId) {
    let regionArr = this.signInFormGroup.value.region;
    let request = [];
    if(regionArr.length > 0){
      for (let index = 0; index < regionArr.length; index++) {
        let obj = {
          regionId: regionArr[index],
          userId: userId,
        };
        let temp = axios.post(`${baseUrl}/user-region-mappers`, obj);
        request.push(temp);
      }
    }

    let sectorArr = this.signInFormGroup.value.sector;
    if(sectorArr.length > 0){
      for (let index = 0; index < sectorArr.length; index++) {
        let obj = {
          sectorId: sectorArr[index],
          userId: userId,
        };
        let temp = axios.post(`${baseUrl}/user-sector-mappers`, obj);
        request.push(temp);
      }
    }
    await Promise.all(request);
    this.deleteSectorRegion()
  }

  deleteSectorRegion(){
    this.initialSectorQues['sector'].forEach(element => {
      if(!this.signInFormGroup.value.sector.includes(element)){
        this.regService.deleteSectorMapper(element,this.userId).subscribe(
          (res)=>{},
          (error)=>{})
      }
    });

    this.initialSectorQues['region'].forEach(element => {
      if(!this.signInFormGroup.value.region.includes(element)){
        this.regService.deleteRegionMapper(element,this.userId).subscribe(
          (res)=>{},
          (error)=>{})
      }
    });
  }

  goBack() {
    this.router.navigate(['sign-in']);
  }


}
function sortByKey(array, key) {
  return array.sort(function (a, b) {
    var x = a[key];
    var y = b[key];
    return x < y ? -1 : x > y ? 1 : 0;
  });
}

import { Injectable } from '@angular/core';
import { Router,CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SecurityGuardGuard implements CanActivate {

  constructor(
      private router: Router,
      private authenticationService: AuthService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser) {
      if(currentUser.user.user_level !== 'Admin' && (window.location.pathname == '/sector' || window.location.pathname == '/admin-dashboard' || window.location.pathname == '/users')){
        return false;
      }
      // logged in so return true
      return true;
    }
    // not logged in so redirect to sign-in page with the return url
    this.router.navigate(['/sign-in']);
    return false;
  }
  
}

import { Component, OnInit } from '@angular/core';
import { SocialAuthService } from "angularx-social-login";
import { log } from 'console';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  public checkLogin = true;
  levelId  :any;
  isCollapsed = false;
  constructor(private authService: SocialAuthService,
    private cookieService: CookieService,
    private authenticationService: AuthService
    ) { }
 userfName = '';
 userlName = '';
 userEmail = '';
  ngOnInit(): void {
    this.checkLogin = localStorage.getItem('loggedIn') ? true : false;
    let userDetails = JSON.parse(localStorage.getItem('currentUser'));
    this.levelId = localStorage.getItem('loggedIn') ? (this.authenticationService.currentUserValue.user.user_level == 'Admin' ? true : false) : false;
    if(userDetails){
      this.userfName = userDetails['user'].firstName
      this.userlName =  userDetails['user'].lastName;
      this.userEmail = userDetails['user'].username;
    }
  }

  signOut(): void {
    this.authService.signOut();
    localStorage.clear();
    this.cookieService.delete("tocUrl","/",".idinsight.org")
    this.cookieService.delete("user","/",".idinsight.org")
    this.cookieService.set(
      'isLoggedIn',
      "false",100,
      '/',
      '.idinsight.org'
    );
    this.cookieService.set(
      'userId',
      "null",100,
      '/',
      '.idinsight.org'
    );
    window.location.href = '/sign-in'
  }
  updateProfie(){

  }

}


import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HomeComponent } from './components/home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatButtonModule} from '@angular/material/button';
import {MatSnackBar,MatSnackBarModule} from '@angular/material/snack-bar';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import { EmailComponent } from './components/email/email.component';
import {MatMenuModule} from '@angular/material/menu';
import { SignInComponent } from './components/sign-in/sign-in.component';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import { RegistrationComponent } from './components/registration/registration.component';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {AngularDiagram} from 'diagram-library-angular';
import { NgxOrgChartModule } from 'ngx-org-chart';
import { SocialUser, GoogleLoginProvider} from 'angularx-social-login';
import { SearchfilterPipe } from './searchfilter.pipe';
import { SocialMediaComponent } from './components/social-media/social-media.component';
import { ChartComponent } from './chart/chart.component';
import { TourComponent } from './components/tour/tour.component';
import { SurveyBuilderComponent } from './survey-builder/survey-builder.component';
//import { ChartsModule } from 'ng2-charts';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatListModule} from '@angular/material/list';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SharedModule }    from './shared/shared.module'
import { questionType } from './question/question-type';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { UpdateProfileComponent } from './components/update-profile/update-profile.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { SignOutComponent } from './components/sign-out/sign-out.component';
import { DrawioComponent } from './drawio/drawio.component';
@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    EmailComponent,
    SignInComponent,
    RegistrationComponent,
    SearchfilterPipe,
    SocialMediaComponent,
    ChartComponent,
    TourComponent,
    SurveyBuilderComponent,
    UpdateProfileComponent,
    SignOutComponent,
    DrawioComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatCardModule,
    MatInputModule,
    MatSelectModule,
    MatGridListModule,
    MatCheckboxModule,
    SocialLoginModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MatDialogModule,
    MatProgressBarModule,
    AngularDiagram,
    NgxOrgChartModule,
    MatTooltipModule,
    MatListModule,
    BsDropdownModule.forRoot(),
    SharedModule,
    TooltipModule.forRoot(),
    CollapseModule.forRoot(),
   // ChartsModule
  ],
  exports : [
    ReactiveFormsModule,
    FormsModule
  ],
  providers: [MatSnackBar,SocialUser,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '246130207963-7k6a9r4no9jkv7c87etkl6380tdsi6oi.apps.googleusercontent.com'
            )
          }
        ]
      } as SocialAuthServiceConfig,
    },
    {
      provide: 'questionType', useValue: questionType
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

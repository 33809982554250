import { Component, NgZone, OnInit } from '@angular/core';
declare var Tour: any;
declare var $: any;

@Component({
  selector: 'app-tour',
  templateUrl: './tour.component.html',
  styleUrls: ['./tour.component.css']
})
export class TourComponent implements OnInit {
  tour: any;
  constructor(private _ngZone: NgZone) { }

  ngOnInit(): void {
    this._ngZone.runOutsideAngular(() => {
      this.tour = new Tour({
        steps: [
          {
            element: "#panelB",
            title: "2nd Panel",
            content: "This is the second panel",
            placement: "bottom"
          },
          {
            element: "#panelA",
            title: "1st Panel",
            content: "This is the first panel",
            placement: "bottom"
          },
          {
            element: "#panelC",
            title: "3rd Panel",
            content: "This is the third panel",
            placement: "bottom"
          }
        ],
        backdrop: true,
        storage: false,

      });
    });

    this.tour.init();
    this.tour.start(true);
    console.log("this.tour",this.tour)
    $("#startTourBtn").click(function() {
      this.tour.restart();
    });
  }

}

<mat-card class="mainCard">
    <div class="row">
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8">
                    <form class="example-form" [formGroup]="signInFormGroup">
                        <table class="example-full-width" cellspacing="0">
                            <tr>
                                <td>
                                    <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>Username</mat-label>
                                        <input matInput formControlName="identifier">
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>Password</mat-label>
                                        <input matInput type="password" formControlName="password">
                                    </mat-form-field>
                                    <small class="text-danger" *ngIf="Error"> {{Error}}</small>

                                </td>
                            </tr>
                            <tr>
                                <section class="example-section" style="float: left;">
                                    <mat-checkbox class="example-margin maineClr">Remember</mat-checkbox>
                                </section>
                                <a class="forgot maineClr" style="cursor: pointer;" (click)="openRestPass()">Forgot
                                    Password?</a>
                            </tr>
                            <tr>
                                <td>
                                    <button mat-flat-button (click)="doLogin()" style="
                                     color: white;" class="example-full-width signBtn">Sign In</button>
                                </td>
                            </tr>
                            <mat-progress-bar mode="indeterminate" *ngIf="loginContinue"></mat-progress-bar>
                            <tr style="text-align: center;">
                                <span>- or -</span>
                            </tr>
                            <tr>
                                <td>
                                    <!-- href="{{baseUrl}}/connect/google" -->
                                    <a (click)="googleSignUp()" style="color: #472666;" class="btn example-full-width btnGoogle">
                                        <!-- <button mat-stroked-button style="color: #472666;" class="example-full-width btnGoogle" > -->
                                        <img src="https://img.icons8.com/color/48/000000/google-logo.png" style="height: 24px; padding-right: 4%;" /> Continue with Google
                                        <!-- </button> -->
                                    </a>
                                </td>
                            </tr>
                        </table>
                    </form>
                </div>
                <div class="col-md-2"></div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="signUpSideClass">
                <h1 class="maineClr">Don't have an account?</h1>
                <h2 class="maineClr">Sign up now and start using</h2>
                <h2 class="maineClr">Impact Measurment Guide portal features</h2>
                <div class="row">
                    <div class="col">
                        <i class="fa fa-check mb-3" aria-hidden="true"></i> &nbsp; &nbsp; &nbsp;Save Theory of change
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <i class="fa fa-check mb-3" aria-hidden="true"></i> &nbsp; &nbsp; &nbsp;Create Indicators
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <i class="fa fa-check mb-4" aria-hidden="true"></i> &nbsp; &nbsp; &nbsp;Create Surveys
                    </div>
                </div>
                <a routerLink="/sign-up" class="btn signBtn">Sign Up</a>
            </div>
        </div>
    </div>
</mat-card>

<ng-template #ResetPass>
    <div class="row" style="overflow-x: hidden;">
        <div class="row col-sm-12">
            <!-- <button mat-button class="close-icon text-end resetClose" [mat-dialog-close]="true" (click)="closeDialog()"> -->
                <span class="text-end resetClose" (click)="closeDialog()">
                    <mat-icon class="text-end resetClose">close</mat-icon>
                </span>
            <!-- </button> -->
            <h2 *ngIf="!emailVerified" class="text-center maineClr">Find Account</h2>
            <h2 *ngIf="emailVerified && !otpVerified" class="text-center maineClr">Verify OTP</h2>
            <h2 *ngIf="otpVerified" class="text-center maineClr">Update Password</h2>
        </div>
        <hr>
        <div class="row col-sm-12">
            <form [formGroup]="resetPasswordForm">
                <div *ngIf="!emailVerified" class="col-sm-12">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Email</mat-label>
                        <input formControlName="email" type="email" matInput placeholder="Ex. pat@example.com">
                    </mat-form-field>
                    <span *ngIf="emailError" class="text-danger">
                        {{emailError}}
                    </span>
                </div>
                <div *ngIf="emailVerified && !otpVerified" class="col-sm-12">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>OTP</mat-label>
                        <input formControlName="otp" type="text" matInput placeholder="Ex. 1234">
                    </mat-form-field>
                    <span *ngIf="otpError" class="text-danger">
                        {{otpError}}
                    </span>
                </div>
                <div *ngIf="otpVerified" class="col-sm-12">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>New Password</mat-label>
                        <input formControlName="password" type="password" matInput placeholder="Ex. 1234">
                        <mat-icon matSuffix style="cursor: pointer;" matTooltip="Password must be at least 6 characters.">info</mat-icon>
                            <mat-hint class="text-danger" *ngIf="resetPasswordForm.get('password').hasError('minlength')">
                                Password must be at least 6 characters.</mat-hint>
                    </mat-form-field>
                </div>
                <div *ngIf="otpVerified" class="col-sm-12">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Confirm Password</mat-label>
                        <input type="password" formControlName="confirmPassword" matInput placeholder="Ex. 1234">
                        <mat-icon matSuffix style="cursor: pointer;" matTooltip="Password and Confirm Password must be same">info</mat-icon>
                            <mat-hint class="text-danger" *ngIf="resetPasswordForm.get('confirmPassword').hasError('mustMatch')">
                                Password and Confirm Password must match.
                            </mat-hint>
                    </mat-form-field>
                </div>
                <div class="col-sm-12 text-center">
                    <button (click)="resetPassword()" class="btn signBtn mb-1">{{resetPassBtn}}</button>
                </div>
            </form>
        </div>
    </div>
</ng-template>

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-node-instruction',
  templateUrl: './node-instruction.component.html',
  styleUrls: ['./node-instruction.component.css']
})
export class NodeInstructionComponent implements OnInit {

  constructor(
    private dialog : MatDialog,
    private router : Router
  ) { }

  isCollapsedInput = true;
  isCollapsedActi = true;
  isCollapsedOutput = true;
  isCollapsedOutcom = true;
  inputBtnName = 'Read more...'
  actiBtnName = 'Read more...'
  outComBtnName = 'Read more...'
  outputBtnName = 'Read more...'
  ngOnInit(): void {
  }

  dialogClose(){
    this.dialog.closeAll()
  }
  collapsed(type): void {
    if(type == 'input'){
      this.inputBtnName = 'Read more...';
    }
    if(type == 'acti'){
      this.actiBtnName = 'Read more...';
    }
    if(type == 'outCom'){
      this.outComBtnName = 'Read more...';
    }
    if(type == 'output'){
      this.outputBtnName = 'Read more...';
    }

  }
  expanded(type): void {
    if(type == 'input'){
      this.inputBtnName = 'Read less...';
    }
    if(type == 'acti'){
      this.actiBtnName = 'Read less...';
    }
    if(type == 'outCom'){
      this.outComBtnName = 'Read less...';
    }
    if(type == 'output'){
      this.outputBtnName = 'Read less...';
    }
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseUrl } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  private filterSource = new BehaviorSubject<object>({});
  currentFilter = this.filterSource.asObservable();
  constructor(private http:HttpClient) { }

  useFilter(obj){
    this.filterSource.next(obj);
  }

  listQuestion(){
    return this.http.get(`${baseUrl}/questions?_sort=updated_at:desc`);
  }

  listFilterQuestion(obj){
    obj['_sort'] =  'updated_at:desc';
    obj['createFrom'] =  true;
    return this.http.post(`${baseUrl}/questions/filter`,obj);
  }  

  loadSectorList(){
    return this.http.get(`${baseUrl}/sectors?_sort=updated_at:desc`);
  }

  deleteList(id){
    return this.http.delete(`${baseUrl}/questions/${id}`);
  }

  findOne(id){
    return this.http.get(`${baseUrl}/questions/${id}`);
  }

  createForm(data){
    return this.http.post(`${baseUrl}/questions`,data);
  }

  updateForm(id,data){
    return this.http.put(`${baseUrl}/questions/${id}`,data);
  }

  createMapperForm(data){
    return this.http.post(`${baseUrl}/survey-question-mappers`,data);
  }

  deleteMapperForm(obj){
    return this.http.post(`${baseUrl}/survey-question-mappers/delete`,obj);
  }

}

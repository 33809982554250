import { Component, EventEmitter, Inject, OnInit,Output } from '@angular/core';
import { IndicatorService } from '../indicator/indicator.service';
import { AuthService } from '../services/auth.service';
import { SurveyServicesService } from './survey-services.service';
import { Router, NavigationStart, Event,NavigationEnd } from "@angular/router";
import { UserService } from '../user/user.service';
import { QuestionService } from '../question/question.service';

@Component({
  selector: 'app-survey-builder',
  templateUrl: './survey-builder.component.html',
  styleUrls: ['./survey-builder.component.css']
})


export class SurveyBuilderComponent implements OnInit {
  @Output() sectorIdEvent = new EventEmitter<string>();
  active: boolean;
  activeAllSurvey = false;
  header ='';
  constructor
  (
    private indicatorService : IndicatorService,
    private surveyServices : SurveyServicesService,
    public questionService: QuestionService,
    private authenticationService : AuthService,
    private router: Router,
    private loadUser: UserService,
    public surveyService: SurveyServicesService,
    @Inject('questionType') public questionType: any[],
  ) {
    this.active = false;
    this.router.events.subscribe((event:Event)=>{
      let urlArray;
      // if (event instanceof NavigationStart) {
      //   urlArray = event.url.split("/")
      //   // if(urlArray.includes('createSurvey')){
      //   //   this.showSurveyFilter = false;
      //   //   this.active = false;
      //   // }else{
      //   //   this.showSurveyFilter = true
      //   // }
      // }
      if (event instanceof NavigationEnd) {
        urlArray = event.url.split("/")
        if(urlArray.includes('createSurvey')){
          this.showSurveyFilter = false
          this.showQuestionFilter = false;
          this.header = 'Create New Survey';
        }
       else if(urlArray.includes('mySurvey')){
          this.showSurveyFilter = true;
          this.showQuestionFilter = false;
          // this.activeAllSurvey = this.active ? false : true;
          this.header = 'My Survey';
        }
        else if(urlArray.includes('questionBank')){
          this.showSurveyFilter = false;
          this.showQuestionFilter = true;
          this.header = 'Question Bank';
        }
        else{
          this.showSurveyFilter = true;
          this.showQuestionFilter = false;
          // this.activeAllSurvey = false;
        }
      }
    })
  }

  showSurveyFilter = true;
  showQuestionFilter = false;
  sectorList;
  public filterObj = {};
  sectorId: any;
  formName: any;
  ngOnInit(): void {
    this.indicatorService.loadSectorList()
    .subscribe(data => {
      this.sectorList = data;
      sessionStorage.setItem("sectorList", JSON.stringify(this.sectorList));
    });
  }

  filterSector(value,from){
    if(from == 'survey'){
      if(this.filterObj.hasOwnProperty("question_type")){
        delete this.filterObj["question_type"];
      }
      if(this.filterObj.hasOwnProperty("question_name_contains")){
        delete this.filterObj["question_name_contains"];
      }
      if(value && value != 'Select'){
        this.sectorId = value;
        this.filterObj["sectorId"] = value;
      }else{
        this.sectorId = ''
        delete this.filterObj["sectorId"];
      }
      this.surveyServices.useFilter(this.filterObj);
    }else{
      if(this.filterObj.hasOwnProperty("sectorId")){
        delete this.filterObj["sectorId"];
      }
      if(this.filterObj.hasOwnProperty("formName_contains")){
        delete this.filterObj["formName_contains"];
      }
      if(value && value != 'Select'){
        // this.sectorId = value;
        this.filterObj["question_type"] = value;
      }else{
        // this.sectorId = ''
        delete this.filterObj["question_type"];
      }
      this.questionService.useFilter(this.filterObj);
    }
  }

  filterName(event,from){
    if(from == 'survey'){
      if(this.filterObj.hasOwnProperty("question_type")){
        delete this.filterObj["question_type"];
      }
      if(this.filterObj.hasOwnProperty("question_name_contains")){
        delete this.filterObj["question_name_contains"];
      }
      if(event.target.value){
        this.formName = event.target.value;
        this.filterObj["formName_contains"] = event.target.value;
      }else{
        this.formName = ''
        delete this.filterObj["formName_contains"];
      }
      this.surveyServices.useFilter(this.filterObj);
    } else {
      if(this.filterObj.hasOwnProperty("sectorId")){
        delete this.filterObj["sectorId"];
      }
      if(this.filterObj.hasOwnProperty("formName_contains")){
        delete this.filterObj["formName_contains"];
      }
      if(event.target.value){
        // this.formName = event.target.value;
        this.filterObj["question_name_contains"] = event.target.value;
      }else{
        // this.formName = ''
        delete this.filterObj["question_name_contains"];
      }
      this.questionService.useFilter(this.filterObj);
    }
  }

  resetFilter(){
    this.active = false;
    location.reload();
    if(this.filterObj.hasOwnProperty("sectorId")){
      delete this.filterObj["sectorId"];
    }
    if(this.filterObj.hasOwnProperty("formName_contains")){
      delete this.filterObj["formName_contains"];
    }
    if(this.filterObj.hasOwnProperty("question_type")){
      delete this.filterObj["question_type"];
    }
    if(this.filterObj.hasOwnProperty("question_name_contains")){
      delete this.filterObj["question_name_contains"];
    }
    this.surveyServices.useFilter(this.filterObj);
    this.questionService.useFilter(this.filterObj);
  }

  // getMySubbmitedResult(){
  //   this.activeAllSurvey = false;
  //   this.router.navigate(['/survey-builder/mySurvey']);
  //   this.active = true;
  //   this.filterObj['userId'] = this.authenticationService.currentUserValue.user.id;
  //   this.surveyServices.useFilter(this.filterObj);
  // }

  // allSurveyResult(){
  //   this.router.navigate(['/survey-builder/mySurvey']);
  //   this.active = false;
  //   this.activeAllSurvey = true;
  //   this.loadAdminId();
  // }

  async loadAdminId(){
    let arr = [];
    await this.loadUser.listUser(true).subscribe(dx => {
      if(dx){
        arr.push(this.authenticationService.currentUserValue.user.id);
        Object.keys(dx).map(function (k) {
          // return arr.push(dx[k]["id"]);
        }.bind(this));
      }
      this.filterObj['userId'] = arr;
      this.surveyServices.useFilter(this.filterObj);
      this.questionService.useFilter(this.filterObj);
    });
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { SocialAuthService } from 'angularx-social-login';
import { SocialUser, GoogleLoginProvider } from 'angularx-social-login';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { baseUrl } from 'src/environments/environment';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SigninService } from './signin.service';
import { SweetAlert } from 'sweetalert/typings/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomValidators } from 'src/app/services/custom_validators';
import { UpdateProfileComponent } from '../update-profile/update-profile.component';
const swal: SweetAlert = require('sweetalert');
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css'],
})
export class SignInComponent implements OnInit {
  user = new SocialUser();
  loginContinue: boolean;
  Error: string = '';
  baseUrl: string;
  public signInFormGroup: FormGroup;
  resetPasswordForm: FormGroup;
  dialogRef;
  @ViewChild('ResetPass') template;
  emailVerified = false;
  otpVerified = false;
  resetPassBtn = 'Send Email';
  resetUserId;
  emailError;
  otpError;

  constructor(
    private socialAuthService: SocialAuthService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private matDialogModule: MatDialog,
    private signinService: SigninService,
    private router: Router,
    private cookieService: CookieService
  ) {
    if (this.authService.currentUserValue) {
      this.router.navigateByUrl('tocBuilder/index');
    }
  }

  ngOnInit(): void {
    this.baseUrl = baseUrl;
    this.loginContinue = false;
    this.signInFormGroup = this.formBuilder.group({
      identifier: ['', Validators.required],
      password: ['', Validators.required],
    });

    this.resetPasswordForm = this.formBuilder.group(
      {
        email: ['', [Validators.required, Validators.email]],
        otp: [''],
        password: ['', [Validators.required, Validators.minLength(6)]],
        confirmPassword: [''],
      },
      {
        validator: CustomValidators.MustMatch('password', 'confirmPassword'),
      }
    );

    this.socialAuthService.authState.subscribe((user) => {
      // console.log('dfgtyh', user)
      this.user = user;
    });
  }

  signInWithGoogle(): any {
    this.socialAuthService
      .signIn(GoogleLoginProvider.PROVIDER_ID)
      .then((user) => {
        this.user = user;
        // console.log('user signin', user);

        localStorage.setItem('loggedIn', user.authToken);
        this.saveTocUrl(user.id, true);
      });
  }
  googleSignUp() {
    window.location.href = `${this.baseUrl}/connect/google`;
  }

  saveTocUrl(userId, formGoogle = false) {
    let currentUser = this.authService.currentUserValue.user;
    // let currentUser = this.authService.currentUserValue;
      if(currentUser.user_level === "Admin"){
        this.cookieService.set(
          'userType',
          "Admin",
          100,
          '/',
          '.idinsight.org'
        );
      }else{
        this.cookieService.set(
          'userType',
          "NGO",
          100,
          '/',
          '.idinsight.org'
        );
      }
    // this.cookieService.set('userType', currentUser.role, 100, '/', '.dhwaniris.in');
    this.cookieService.set(
      'firstName',
      currentUser.firstName,
      100,
      '/',
      '.idinsight.org'
    );
    this.cookieService.set(
      'lastName',
      currentUser.lastName,
      100,
      '/',
      '.idinsight.org'
    );
    this.cookieService.set('isLoggedIn', 'true', 100, '/', '.idinsight.org');
    this.cookieService.set('userId', userId, 100, '/', '.idinsight.org');
    this.signinService.getTocUrl(userId).subscribe(
      (res) => {
        this.cookieService.set(
          'tocUrl',
          res['tocUrl'],
          100,
          '/',
          '.idinsight.org'
        );
        localStorage.setItem('tocUrl', res['tocUrl']);
        if (formGoogle) window.location.href = '/';
        else window.location.href = '/tocBuilder/index';
      },
      (error) => {
        window.location.href = '/tocBuilder/index';
        console.log(error);
      }
    );
  }

  // signInWithFB(): void {
  //   this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  // }

  signOut(): void {
    this.socialAuthService.signOut();
    this.authService.logout();
  }

  doLogin() {
    // this.signinService.checkProfile(email).subscribe((res)=> {

    // })
    if (this.signInFormGroup.valid) {
      this.loginContinue = true;
      this.authService.login(this.signInFormGroup.value).subscribe(
        (result: any) => {
          if (result) {
            setTimeout(() => {
              let userId =
                JSON.parse(localStorage.getItem('currentUser'))?.user.id ??
                null;
              this.saveTocUrl(userId);
            }, 3000);
          }
        },
        (error) => {
          this.loginContinue = false;
          this.Error = error.error.message[0].messages[0].message;
        }
      );
    }
  }

  openRestPass() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    this.dialogRef = this.matDialogModule.open(this.template, dialogConfig);
  }

  closeDialog() {
    this.emailVerified = false;
    this.otpVerified = false;
    this.resetPassBtn = 'Send Email';
    this.resetUserId = null;
    this.emailError = null;
    this.otpError = null;
    this.resetPasswordForm.reset();
    this.dialogRef.close();
  }

  resetPassword() {
    let body;
    switch (this.resetPassBtn) {
      case 'Verify':
        body = {
          otp: this.resetPasswordForm.value.otp,
          email: this.resetPasswordForm.value.email,
        };
        this.signinService.checkOtp(body).subscribe(
          (res: any) => {
            this.resetUserId = res.id;
            this.resetPassBtn = 'Confirm';
            this.otpVerified = true;
          },
          (error) => {
            this.otpError = error.error.message;
          }
        );
        break;

      case 'Send Email':
        body = {
          email: this.resetPasswordForm.value.email,
        };
        this.signinService.checkEmail(body).subscribe(
          (res) => {
            this.emailVerified = true;
            this.resetPassBtn = 'Verify';
          },
          (error) => {
            this.emailError = error.error.message;
          }
        );
        break;

      case 'Confirm':
        if (!this.resetPasswordForm.valid) {
          return;
        }
        this.signinService
          .updateUser(this.resetUserId, this.resetPasswordForm.value)
          .subscribe(
            (res) => {
              this.dialogRef.close();
              swal({
                title: 'Updated!',
                text: 'You have successfully Updated Password',
                icon: 'success',
              });
            },
            (error) => {}
          );
        break;
    }
  }
  openDialog() {
    // this.dialog.open(TocDialogComponent)
    const dialogRef = this.matDialogModule.open(UpdateProfileComponent, {
      width: '95%',
      // maxWidth: '80vw',
      // maxHeight: '50vw',
      height: '90%',
    });
    dialogRef.afterClosed().subscribe((result) => {
      // console.log(`Dialog result: ${result}`);
    });
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CollapseModule } from 'ngx-bootstrap/collapse';
import { HomeHeaderComponent } from './components/home-header/home-header.component';
import { FooterComponent } from './components/footer/footer.component';
import {MatIconModule} from '@angular/material/icon';
import { NodeInstructionComponent } from './components/node-instruction/node-instruction.component';


@NgModule({
  declarations: [HomeHeaderComponent, FooterComponent, NodeInstructionComponent],
  imports: [
    CommonModule,
    MatIconModule,
    BrowserAnimationsModule,
    CollapseModule.forRoot(),
  ],
  exports : [
    FooterComponent,
    HomeHeaderComponent
  ],
})
export class SharedModule { }

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseUrl } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class IndicatorService {

  constructor(private http:HttpClient) { }

  listIndicator(filterObj){
    filterObj['_sort'] =  'updated_at:desc';
    return this.http.post(`${baseUrl}/indicators/get`,filterObj);
  }

  loadSectorList(){
    return this.http.get(`${baseUrl}/sectors?_sort=updated_at:desc`);
  }

  loadQuestionList(obj){
    obj['_sort'] =  'updated_at:desc';
    obj['createFrom'] =  true;
    return this.http.post(`${baseUrl}/questions/filter`,obj);
  }

  deleteList(id){
    return this.http.delete(`${baseUrl}/indicators/${id}`);
  }

  findOne(id){
    return this.http.get(`${baseUrl}/indicators/${id}`);
  }

  createForm(data){
    return this.http.post(`${baseUrl}/indicators`,data);
  }

  updateForm(id,data){
    return this.http.put(`${baseUrl}/indicators/${id}`,data);
  }

  sectorMapperForm(data){
    return this.http.post(`${baseUrl}/indicator-sector-mappers`,data);
  }

  questionMapperForm(data){
    return this.http.post(`${baseUrl}/indicator-question-mappers`,data);
  }

  deleteSectorMapper(sectorId,indicatorId){
    return this.http.delete(`${baseUrl}/indicator-sector-mappers?sectorId=${sectorId}&indicatorId=${indicatorId}`);
  }

  deleteQuesMapper(questionId,indicatorId){
    return this.http.delete(`${baseUrl}/indicator-question-mappers?questionId=${questionId}&indicatorId=${indicatorId}`);
  }

  listQuestion(filterObj){
    filterObj['_sort'] =  'updated_at:desc';
    return this.http.post(`${baseUrl}/indicator-question-mappers/list`,filterObj);
  }
}

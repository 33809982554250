import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseUrl } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http:HttpClient) { }

  listUser(stringFilter = false){
    let filter = stringFilter ? "user_level=Admin&"+'_sort=updated_at:desc' : '_sort=updated_at:desc';
    return this.http.get(`${baseUrl}/users?${filter}`);
  }

}

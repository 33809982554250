import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { baseUrl } from 'src/environments/environment';
import { User } from '../_models/user';
import { map, catchError } from 'rxjs/operators';
import { LandingPageService } from '../pages/services/landing-page.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';


@Injectable({ providedIn: 'root' })
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public tocBuilderFormGroup: FormGroup;

  constructor(
    private http: HttpClient,
    private updateUserId: LandingPageService,
    private formBuilder: FormBuilder,
    private cookieService: CookieService,

  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
    this.tocBuilderFormGroup = this.formBuilder.group({
      userId: [''],
      uniqueId: [''],
    });
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(data) {
    return this.http.post<any>(`${baseUrl}/auth/local`, data).pipe(
      map((user) => {
        const prms1 = new Promise((resolve, reject) => {
          if (localStorage.getItem('uniqueUserConfirmationId')) {
            this.tocBuilderFormGroup.value.userId = user.user.id;
            this.tocBuilderFormGroup.value.uniqueId = localStorage.getItem(
              'uniqueUserConfirmationId'
            );
            this.updateUserId
              .updateUser(this.tocBuilderFormGroup.value)
              .subscribe(
                (data) => resolve(true),
                (err) => reject(false)
              );
          } else {
            resolve(true);
          }
        });
        const prms2 = new Promise((resolve, reject) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          localStorage.setItem('loggedIn', user.jwt);
          this.currentUserSubject.next(user);
          if (localStorage.getItem('uniqueUserConfirmationId')) {
            localStorage.removeItem('uniqueUserConfirmationId');
          }
          resolve(true);
        });
        return Promise.all([prms1, prms2]).then((values) => {
          if (values[0] === true && values[1] === true) {
            return true;
          }
        });
      })
    );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('uniqueUserConfirmationId');
    localStorage.clear();
    this.cookieService.delete("tocUrl","/",".idinsight.org")
    window.location.href = '/sign-in';
    this.currentUserSubject.next(null);
  }

  googleSignIn(id_token, access_token) {
    return this.http
      .get<any>(
        `${baseUrl}/auth/google/callback?id_token=${id_token}&access_token=${access_token}`
      )
      .pipe(
        map((user) => {
          const prms1 = new Promise((resolve, reject) => {
            if (localStorage.getItem('uniqueUserConfirmationId')) {
              this.tocBuilderFormGroup.value.userId = user.user.id;
              this.tocBuilderFormGroup.value.uniqueId = localStorage.getItem(
                'uniqueUserConfirmationId'
              );
              this.updateUserId
                .updateUser(this.tocBuilderFormGroup.value)
                .subscribe(
                  (data) => {
                    console.log('auth data', data);
                    resolve(true)
                  },
                  (err) => reject(false)
                );
            } else {
              resolve(true);
            }
          });
          const prms2 = new Promise((resolve, reject) => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            localStorage.setItem('loggedIn', user.jwt);
            this.currentUserSubject.next(user);
            if (localStorage.getItem('uniqueUserConfirmationId')) {
              localStorage.removeItem('uniqueUserConfirmationId');
            }
            resolve(true);
          });
          return Promise.all([prms1, prms2]).then((values) => {
            if (values[0] === true && values[1] === true) {
              return true;
            }
          });
        })
      );
  }

  uniqueUser(body) {
    return this.http.post(`${baseUrl}/visits`, body);
  }

  updateUniqueUser(body, id) {
    return this.http.put(`${baseUrl}/visits/${id}`, body);
  }

  userIp() {
    return this.http.get(`https://ipapi.co/json/`, {
      headers: {
        Accept: '/',
      },
    });
  }

  checkUniqueId(cookie = '') {
    return this.http.get(`${baseUrl}/visits/uuid?cookie=${cookie}`);
  }
}

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 text-center head">
            <span style="font-weight: 700;">Theory of Change Builder</span>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 text-center sub-head">
            Use our drag-and-drop tool to create a theory of change diagram
        </div>
    </div>
    <div class="row" style="margin: 5% 4% 2% 4%;">
        <div class="col-sm-12 col-md-12 col-lg-12 details">
            <span><b>Narrative theory of change:</b></span><br>
            <span style="color: #161341; font-size: 15px; font-style:Poppins,Regular;"></span><br>
            <br>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12">
            <span style="color: #161341; font-size: 15px; font-style:Poppins,Regular;">
                That same sentence is mapped out as a simple diagram below. This type of diagram is a good starting point, but next you need to add other important details using our drag-and-drop tool. Your goal is to make all key assumptions explicit
            </span><br>
            <br>
            <span style="margin-top: 1rem;">Mapping a detailed theory of change in this way can help you plan for key parts of your program you may otherwise overlook. It can also help you spot weak or untested assumptions you need to investigate further.
        </span>
        </div>
        <div class="row">
            <diagram-view [diagram]="diagram" [allowInplaceEdit]="true" [linkHeadShapeSize]="2" [behavior]="behavior">
            </diagram-view>
        </div>
    </div> 
</div>
